<template>
  <div class="refund">
    <div class="nav">
      <div class="nav_title">退款</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>
    <div class="detail">
      <div class="top">提现类型: 线上提现</div>
      <div v-if="user" class="center">
        当前余额: ￥{{ user.balance ? (user.balance / 100).toFixed(2) : 0.0 }}
      </div>
      <div class="bottom">
        注: 线下充值或线上充值时间超出银行退款时间请通过线下方式手动退款
      </div>
    </div>
    <div class="submit_btn" @click="refundFn">确认提现</div>
    <div class="tips">
      说明:
      当前余额不一定等于可提现金额，请以实际退款金额为主，若存在疑问请联系管理员。
    </div>
    <van-overlay :show="loading" class="init">
      <van-loading size="24px" color="#333333" vertical>
        退款处理中...
      </van-loading>
    </van-overlay>
    <div class="dialogdialog"></div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant';
import { batchRefundAPI, queryEmployee, refundBatch } from '@/api/api';
import cancel from '@/assets/cancel.png';
export default {
  data() {
    return {
      cancel,
      loading: false,
      user: {},
      detail: []
    };
  },
  created() {
    queryEmployee().then((res) => {
      if (res.code === 0) {
        this.user = res.data;
        sessionStorage.setItem('personInfo', JSON.stringify(res.data));
      }
    });
    // this.user = JSON.parse(sessionStorage.getItem("personInfo"));
  },
  methods: {
    async refundFn() {
      try {
        if (!this.user.balance) {
          Toast('当前现金余额为0!');
          return;
        }
        if (this.user.balance < 0) {
          Toast('余额不足!');
          return;
        }
        this.loading = true;

        const res = await refundBatch({
          userId: this.user.id
        });
        if (res.code === 0) {
          await queryEmployee().then((res) => {
            if (res.code === 0) {
              this.user = res.data;
              sessionStorage.setItem('personInfo', JSON.stringify(res.data));
            }
          });
          Toast.success('退款流程处理中,详情请前往账单查看!');
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
      // batchRefundAPI(this.user.organization.id).then((res) => {
      //   if (res.code === 0) {
      //     if (res.data.msg && res.data.code === '1001') {
      //       this.loading = false;
      //       Dialog.alert({
      //         title: '退款处理中',
      //         message: '退款进度请在账单中进行查看',
      //         confirmButtonColor: '#000000',
      //         getContainer: '.refund'
      //       }).then(() => {
      //         // on close
      //       });
      //     } else {
      //       if (res.data.length === 0) {
      //         Toast('暂无可退款记录!');
      //         this.loading = false;
      //       } else {
      //         this.loading = false;
      //         this.detail = res.data;
      //         this.$router.push({
      //           name: 'RefundDetail',
      //           query: { detail: JSON.stringify(this.detail) }
      //         });
      //       }
      //     }
      //   } else {
      //     this.loading = false;
      //   }
      // });
    },
    toBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.refund {
  color: #333333;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  .nav {
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .Refund_top {
    width: 100%;
    background: rgba(255, 255, 255);
    display: flex;
    height: 40px;
    align-items: center;
    .left {
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 20px;
        height: 24px;
      }
    }
    .right {
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: 900;
      color: #333333;
    }
  }
  .detail {
    width: calc(100vw - 40px);
    margin: 10px auto;
    background: #ffff;
    font-size: 18px;
    font-weight: 900;
    padding: 25px 0 10px;
    border-radius: 10px;
    .top,
    .center {
      margin-left: 15px;
      margin-bottom: 25px;
    }
    .bottom {
      margin-left: 15px;
      font-size: 0.5rem;
      color: red;
    }
  }
  .submit_btn {
    width: calc(100vw - 40px);
    margin: 20px auto;
    height: 50px;
    background: rgba(245, 154, 35);
    color: white;
    font-size: 20px;
    font-weight: 900;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
  }
  .tips {
    width: calc(100vw - 40px);
    margin: 0 auto;
    text-align: center;
    font-size: 0.5rem;
    color: red;
  }
  .init {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>
